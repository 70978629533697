import { GoogleOutlined } from "@ant-design/icons";
import { Button, Card, Typography } from "antd";
import { FC } from "react";

import { useGoogleLogin } from "@react-oauth/google";
import { login } from "../services/auth";

const Login: FC = () => {
  const handleLogin = useGoogleLogin({
    onSuccess: (response) => login(response.access_token),
  });

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Card className="w-full max-w-sm p-6">
        <Typography.Title level={3} className="text-center mb-4">
          Komeng
        </Typography.Title>
        <Button
          type="primary"
          size="large"
          className="w-full"
          icon={<GoogleOutlined />}
          onClick={() => handleLogin()}
        >
          Login with Google
        </Button>
        <div className="text-center pt-3">
          <Typography.Text type="secondary">v2.0.0</Typography.Text>
        </div>
      </Card>
    </div>
  );
};

export default Login;
