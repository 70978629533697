import { Button, Layout, Space, Typography } from "antd";
import { FC } from "react";

const Header: FC = () => {
  return (
    <Layout.Header
      style={{
        padding: 0,
        background: "#fff",
      }}
    >
      <div className="flex justify-end pr-3">
        <Space>
          <Space>
            {/* <Avatar
              icon={<UserOutlined />}
              size="large"
              style={{
                backgroundColor: "#87d068",
              }}
            /> */}
            <Typography.Text>Febriyan Adji</Typography.Text>
            <Typography.Text type="secondary">
              ( DevOps Engineer )
            </Typography.Text>
          </Space>
          <Button type="primary" ghost>
            Logout
          </Button>
        </Space>
      </div>
    </Layout.Header>
  );
};

export default Header;
