import { FC } from "react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { GoogleOAuthProvider } from "@react-oauth/google";
import config from "./config";

const App: FC = () => {
  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <RouterProvider router={router} />
    </GoogleOAuthProvider>
  );
};

export default App;
