import { Layout } from "antd";
import { FC } from "react";

const Footer: FC = () => {
  return (
    <Layout.Footer className="text-center">
      Komerce Engineering Platform © {new Date().getFullYear()}
    </Layout.Footer>
  );
};

export default Footer;
