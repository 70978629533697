interface IGetApplicationsResponse {
  data: {
    id: string;
    name: string;
    namespace: { name: string };
    git_repository_url: string;
  }[];
  metadata: {
    current_page: number;
    page_size: number;
    total: number;
  };
}

export const getApplications = () =>
  new Promise<IGetApplicationsResponse>((resolve, reject) => {
    const response = {
      data: [
        {
          id: "123-123-123-123",
          name: "auth-service",
          namespace: { name: "komship" },
          git_repository_url: "https://gitlab.com/komship/auth-service",
        },
        {
          id: "456-456-456-456",
          name: "order-service",
          namespace: { name: "komtim" },
          git_repository_url: "https://gitlab.com/komtim/order-service",
        },
        {
          id: "678-678-678-90",
          name: "user-service",
          namespace: { name: "komtim" },
          git_repository_url: "https://gitlab.com/komtim/user-service",
        },
        {
          id: "678-678-678-22",
          name: "shipping-service",
          namespace: { name: "komtim" },
          git_repository_url: "https://gitlab.com/komtim/user-service",
        },
        {
          id: "678-678-678-sas",
          name: "chat-service",
          namespace: { name: "kompack" },
          git_repository_url: "https://gitlab.com/komtim/user-service",
        },
        {
          id: "678-678-678-ccc",
          name: "mail-service",
          namespace: { name: "komplace" },
          git_repository_url: "https://gitlab.com/komtim/user-service",
        },
        {
          id: "678-678-678-sdsd",
          name: "phone-service",
          namespace: { name: "komchat" },
          git_repository_url: "https://gitlab.com/komtim/user-service",
        },
      ],
      metadata: {
        current_page: 1,
        page_size: 5,
        total: 20,
      },
    };
    setTimeout(() => {
      resolve(response);
    }, 1000);
  });
