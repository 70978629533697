import {
  BarChartOutlined,
  DashboardOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Tabs } from "antd";
import { TabsType } from "antd/es/tabs";
import { FC } from "react";
import { useParams } from "react-router-dom";

const tabsItems = [
  { label: "Overview", key: "overview", icon: <DashboardOutlined /> },
  {
    label: "Environment Variable",
    key: "environment-variable",
    icon: <SettingOutlined />,
  },
  { label: "Observability", key: "observability", icon: <BarChartOutlined /> },
  {
    label: "Configuration",
    key: "configuration",
    icon: <SettingOutlined />,
  },
];

const ApplicationDetail: FC = () => {
  const { applicationId } = useParams();

  return (
    <div>
      <div className="mb-3 ml-2">
        <Breadcrumb
          items={[
            { title: <HomeOutlined />, href: "/" },
            { title: "Application", href: "/application" },
            { title: "auth-service" },
          ]}
        />
      </div>
      <div className="ml-2">
        <Tabs items={tabsItems} />
      </div>
    </div>
  );
};

export default ApplicationDetail;
