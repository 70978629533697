import {
  AppstoreFilled,
  AreaChartOutlined,
  CloudServerOutlined,
  DatabaseFilled,
  DotChartOutlined,
  MailFilled,
} from "@ant-design/icons";
import { Layout, Menu, Typography } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const menuItems: ItemType<MenuItemType>[] = [
  { label: "Application", key: "application", icon: <AppstoreFilled /> },
  {
    label: "Database",
    key: "database",
    icon: <DatabaseFilled />,
    children: [
      { label: "MySQL / MariaDB", key: "mysql" },
      { label: "MongoDB", key: "mongodb" },
      { label: "PostgreSQL", key: "postgresql" },
      {
        label: "Elasticsearch",
        key: "elasticsearch",
      },
      { label: "Redis", key: "redis" },
    ],
  },
  {
    label: "Messsage Broker",
    key: "messagebroker",
    icon: <MailFilled />,

    children: [
      { label: "RabbitMQ", key: "rabbitmq" },
      // {
      //   label: "Apache Kafka",
      //   key: "apachekafka",
      //   disabled: true,
      // },
    ],
  },
  {
    label: "Observability",
    key: "observability",
    icon: <AreaChartOutlined />,
    children: [
      {
        label: "Grafana",
        key: "grafana",
      },
      // {
      //   label: "Jaeger",
      //   key: "jaeger",
      //   disabled: true,
      // },
      // {
      //   label: "Sentry",
      //   key: "sentry",
      //   disabled: true,
      // },
    ],
  },

  {
    label: "Sonarqube",
    key: "sonarqube",
    icon: <DotChartOutlined />,
  },
  // {
  //   label: "Cloud Storage",
  //   key: "gcs",
  //   icon: <CloudServerOutlined />,
  // },
];

const Sider = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClickMenu = (e: any) => {
    navigate(e.key);
  };

  const currentPath = location.pathname.split("/")[1];

  return (
    <Layout.Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div className="text-white py-3 text-center">
        <Typography.Title level={4} style={{ color: "white" }}>
          KOMENG
        </Typography.Title>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        items={menuItems}
        onClick={handleClickMenu}
        selectedKeys={[currentPath]}
        defaultOpenKeys={["messagebroker", "database", "observability"]}
      />
    </Layout.Sider>
  );
};

export default Sider;
