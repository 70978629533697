import { createBrowserRouter } from "react-router-dom";
import AuthenticatedLayout from "./layout/authenticated-layout";
import NotFound from "./pages/not-found";
import Login from "./pages/login";
import Application from "./pages/application";
import Mysql from "./pages/mysql";
import Mongodb from "./pages/mongodb";
import ApplicationDetail from "./pages/application-detail";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthenticatedLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "application",
        children: [
          { path: "", element: <Application /> },
          { path: ":applicationId", element: <ApplicationDetail /> },
        ],
      },
      {
        path: "mysql",
        element: <Mysql />,
      },
      {
        path: "mongodb",
        element: <Mongodb />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

export default router;
