import {
  Breadcrumb,
  Button,
  Input,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tag,
  Typography,
} from "antd";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getApplications } from "../services/application";
import { HomeOutlined, PlusOutlined } from "@ant-design/icons";

interface IApplicationsTableProps {
  loading: boolean;
  data: {
    id: string;
    name: string;
    namespace: {
      name: string;
    };
    git_repository_url: string;
  }[];
  onClickRow: (id: string) => any;
}

const columns: TableColumnsType = [
  {
    title: "Name",
    dataIndex: "name",
    render: (name: string) => <Typography.Link strong>{name}</Typography.Link>,
  },
  {
    title: "Namespace",
    dataIndex: "namespace",
    width: 10,
    align: "center",
    render: (namespace: { name: string }) => (
      // <Typography.Text type="secondary">{namespace.name}</Typography.Text>
      <Tag color="volcano">{namespace.name}</Tag>
    ),
  },
  {
    title: "Repository",
    dataIndex: "git_repository_url",
    render: (Url: string) => (
      <Typography.Text type="secondary">
        {Url.replace("https://", "")}
      </Typography.Text>
    ),
  },
  {
    title: "Tags",
    dataIndex: "tags",
    render: (tags) => (
      <Space size="small">
        <Tag color="cyan">rabbitmq</Tag>
        <Tag color="cyan">golang</Tag>
        <Tag color="cyan">gin</Tag>
        <Tag color="cyan">mariadb</Tag>
      </Space>
    ),
  },
];

const ApplicationsTable = ({
  data,
  loading,
  onClickRow,
}: IApplicationsTableProps) => {
  return (
    <Table
      onRow={(record) => {
        return { onClick: () => onClickRow(record.id) };
      }}
      rowClassName="cursor-pointer"
      dataSource={data.map((data) => ({ ...data, key: data.id }))}
      loading={loading}
      columns={columns}
    />
  );
};

const NamespaceFilter = () => {
  return (
    <Select style={{ width: 200 }} defaultValue="all">
      <Select.Option value="all">All namespace</Select.Option>
      <Select.Option value="komtim">komtim</Select.Option>
      <Select.Option value="komship">komship</Select.Option>
      <Select.Option value="komplace">komplace</Select.Option>
    </Select>
  );
};

const TagFilter = () => {
  return (
    <Select defaultValue="all" popupMatchSelectWidth style={{ minWidth: 150 }}>
      <Select.Option value="all">All tags</Select.Option>
      <Select.Option value="rabbitmq">rabbitmq</Select.Option>
    </Select>
  );
};

const Search = () => {
  return <Input.Search placeholder="Search application ..." allowClear />;
};

interface IApplication {
  id: string;
  name: string;
  namespace: {
    name: string;
  };
  git_repository_url: string;
}

const Application: FC = () => {
  const navigate = useNavigate();
  const [getApplicationsLoading, setGetApplicationsLoading] =
    useState<boolean>(false);

  const [applications, setApplications] = useState<IApplication[]>([]);

  const getData = async () => {
    try {
      setGetApplicationsLoading(true);
      const response = await getApplications();
      setApplications(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setGetApplicationsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const handleClickApplication = (id: string) => {
    navigate(`/application/${id}`);
  };

  return (
    <div>
      {/* <div className="mb-3 ml-2">
        <Breadcrumb
          items={[
            { title: <HomeOutlined />, href: "/" },
            { title: "Application", href: "/application" },
          ]}
        />
      </div> */}
      <div className="flex justify-between">
        <Space className="mb-2">
          <Search />
          <NamespaceFilter />
          <TagFilter />
        </Space>
        <div>
          <Button type="primary" icon={<PlusOutlined />}>
            New Application
          </Button>
        </div>
      </div>
      <ApplicationsTable
        data={applications}
        loading={getApplicationsLoading}
        onClickRow={handleClickApplication}
      />
    </div>
  );
};

export default Application;
