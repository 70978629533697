import {
  Button,
  Card,
  Input,
  List,
  Modal,
  Select,
  Space,
  Table,
  TableColumnProps,
  TableColumnsType,
  Tabs,
  Typography,
} from "antd";
import { FC, useEffect, useState } from "react";
import { getMysqlServers } from "../services/mysql";

const getColumns = (): TableColumnsType => {
  return [
    {
      title: "Name",
      dataIndex: "name",
    },
    { title: "Type", dataIndex: "type" },
    {
      title: "Hostname",
      dataIndex: "hostname",
    },
    {
      title: "IP Address",
      dataIndex: "address",
    },
    { title: "Port", dataIndex: "port" },
    { title: "Size", dataIndex: "size" },
    {
      align: "right",
      render: () => <Button type="primary">Get credentials</Button>,
    },
  ];
};

const data = [
  {
    name: "prod_auth",
    type: "mariadb",
    hostname: "prod-mariadb-01",
    address: "10.10.10.1",
    port: 3305,
    size: "20M",
  },
  {
    name: "prod_komship",
    type: "mysql",
    hostname: "prod-mysql-01",
    address: "10.10.10.2",
    port: 1000,
    size: "30M",
  },
  {
    name: "prod_komcards",
    type: "mariadb",
    hostname: "prod-mariadb-01",
    address: "10.10.10.3",
    port: 8008,
    size: "15M",
  },
];

const tabsItems = [
  { label: "Database", key: "database" },
  { label: "Server", key: "server" },
  { label: "Credential history", key: "credential" },
];

const DatabaseList: FC = () => {
  return (
    <div>
      <Space className="mb-2">
        <Input.Search placeholder="Search database name ..." />
        <Select style={{ width: 150 }} defaultValue="all">
          <Select.Option value="all">all type</Select.Option>
          <Select.Option value="mariadb">mariadb</Select.Option>
          <Select.Option value="mysql">mysql</Select.Option>
        </Select>
        <Select style={{ width: 150 }} defaultValue="all" popupMatchSelectWidth>
          <Select.Option value="all">all server</Select.Option>
          <Select.Option value="mariadb">prod-mariadb-01</Select.Option>
          <Select.Option value="mysql">prod-mysql-01</Select.Option>
        </Select>
      </Space>
      <Table dataSource={data} columns={getColumns()} />
    </div>
  );
};

interface IGetServerColumns {
  onClickGetCredential(): any;
}

const getServerColumns = ({
  onClickGetCredential,
}: IGetServerColumns): TableColumnsType => [
  { title: "Hostname", dataIndex: "name" },
  {
    title: "Type",
    dataIndex: "type",
    render: (serverType) => (serverType == "mysql" ? "MySQL" : "MariaDB"),
  },
  { title: "Private address", dataIndex: "private_address" },
  {
    title: "Public address",
    dataIndex: "public_address",
    render: (publicAddress: string) => (publicAddress ? publicAddress : "-"),
  },
  { title: "Port", dataIndex: "port" },
  {
    align: "right",
    render: () => (
      <Space>
        <Button type="primary">Detail</Button>
        <Button onClick={onClickGetCredential}>Get credential</Button>
      </Space>
    ),
  },
];

interface IServer {
  name: string;
  type: "mariadb" | "mysql";
  private_address: string;
  public_address?: string;
  port: number;
}

const MysqlServerList: FC = () => {
  const [showCredentialModal, setShowCredentialModal] = useState(false);

  const handleClickGetCredential = () => {
    setShowCredentialModal(true);
  };

  const [getServersLoading, setGetServersLoading] = useState(false);
  const [servers, setServers] = useState<IServer[]>([]);

  const getServers = async () => {
    try {
      setGetServersLoading(true);
      const response = await getMysqlServers();
      setServers(response.data);
    } catch (e) {
      console.error(e);
    } finally {
      setGetServersLoading(false);
    }
  };

  useEffect(() => {
    getServers();
  }, []);

  return (
    <div>
      {/* <GetMysqlCredentialModal
        show={showCredentialModal}
        close={() => setShowCredentialModal(false)}
      /> */}
      <Table
        pagination={false}
        dataSource={servers}
        loading={getServersLoading}
        columns={getServerColumns({
          onClickGetCredential: handleClickGetCredential,
        })}
      />
    </div>
  );
};

const databases = [
  { name: "prod_auth" },
  { name: "prod_shipping" },
  { name: "prod_komcards" },
  { name: "prod_baru" },
  { name: "prod_komship" },
];

interface ICredentialModal {
  show: boolean;
  close(): any;
}

const GetMysqlCredentialModal = ({ show, close }: ICredentialModal) => {
  // const [showModal, setShowModal] = useState(true);
  // const handleCancel = () => {
  //   setShowModal(false);
  // };
  return (
    <Modal title="PROD-MARIADB-01" open={show} footer={null} onCancel={close}>
      {/* <div className="text-center mt-2">
        <Typography.Text>Choose database : </Typography.Text>
      </div> */}
      <List
        className="mt-4"
        // loading
        dataSource={databases}
        renderItem={(item: any) => (
          <List.Item
            actions={[
              <Typography.Text type="secondary">10.2 MB</Typography.Text>,
              <Button type="primary">Get credential</Button>,
            ]}
          >
            <Typography.Text strong>{item.name}</Typography.Text>
          </List.Item>
        )}
      ></List>
    </Modal>
  );
};

const Mysql: FC = () => {
  return (
    <div>
      <Tabs type="card" items={tabsItems} />
      <DatabaseList />
      {/* <MysqlServerList /> */}
    </div>
  );
};

export default Mysql;
