import { Layout } from "antd";
import { FC } from "react";
import Sider from "./sider";
import Header from "./header";
import { Outlet } from "react-router-dom";
import Footer from "./footer";

const AuthenticatedLayout: FC = () => {
  return (
    <Layout hasSider>
      <Sider />
      <Layout style={{ marginLeft: 200 }}>
        <Header />
        <Layout.Content className="p-4">
          <div
            style={{
              backgroundColor: "white",
              minHeight: 570,
              padding: 15,
              borderRadius: 10,
            }}
          >
            <Outlet />
          </div>
        </Layout.Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default AuthenticatedLayout;
